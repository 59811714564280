h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: "Raleway", sans-serif;
  color: #4a4a4a;
}

h1 {
  color: #000;
  font-size: 64px;
  font-weight: 800;
}

h2 {
  font-size: 56px;
  font-weight: 600;
}

h3 {
  font-size: 40px;
  font-weight: 700;
}

h4 {
  font-size: 32px;
  font-weight: 700;
}

h5 {
  font-size: 26px;
  font-weight: 700;
}

li {
  font-size: 14px;
  font-weight: 400;
}

.box {
  border: 1px solid #aebcd2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 53px;
  /* pointer-events: none; */
  margin-bottom: 20px;
}

.App-Navbar {
  background-color: #4aa9ee;
  padding-left: 200px;
  padding-right: 200px;
  color: #000;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.App-NavbarLink {
  color: #000 !important;
  padding-left: 20px;
  padding-right: 20px;
}

.App-NavbarLogout {
  border: 2px black solid;
  border-radius: 10px;
}

button:disabled {
  cursor: not-allowed;
}
