.Login-Box {
    width: 50%;
}

.Login-logo {
    height:  128px;
}

.GeneratePosts-Box {
    width: 50%;
}